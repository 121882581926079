import React from "react"
import Logo from "../components/Logo"

export default () => (
    <div class="container">
      <Logo /> 
      <div class="services">  consultancy &#183; outsourcing &#183; prototyping </div>
      <footer> 
        © Aphilas Studio AB 2019
        <br />
        dev@aphilas.studio
      </footer>
    </div>
)
